<template>
  <a-layout id="components-layout-demo-responsive">
    <a-layout-sider collapsible :width="230">
      <div class="lightspeed-logo">
        <img src="@/assets/lightspeed/logo.png" alt="" />
      </div>
      <a-menu @click="handleMenuItemClicked" :defaultOpenKeys="['teamsos']" class="lightspeed-sidebar-menu" theme="dark"
        mode="inline">
        <a-menu-item key="1">
          <a-icon type="line-chart" />
          <span class="nav-text">Dashboard</span>
        </a-menu-item>
        <a-menu-item key="2">
          <a-icon type="user" />
          <span class="nav-text">Users</span>
        </a-menu-item>
        <a-menu-item key="3">
          <a-icon type="pie-chart" />
          <span class="nav-text">Reports</span>
        </a-menu-item>
        <a-menu-item key="4">
          <a-icon type="global" />
          <span class="nav-text">Internet Access</span>
        </a-menu-item>
        <a-sub-menu key="teamsos" class="teamsos-menu">
          <span slot="title">
            <a-icon type="alert" /><span>teamSOS</span>
          </span>
          <a-menu-item key="lightspeed-lockdown">
            <div class="tsos-menu-inner">
              Lockdown
            </div>
          </a-menu-item>
          <a-menu-item key="lightspeed-announcements">
            <div class="tsos-menu-inner">
              Announcements
            </div>
          </a-menu-item>
          <a-menu-item key="lightspeed-incidents">
            <div class="tsos-menu-inner">
              Incidents
            </div>
          </a-menu-item>
          <a-menu-item key="lightspeed-guidance">
            <div class="tsos-menu-inner">
              Guidance
            </div>
          </a-menu-item>
        </a-sub-menu>
        <a-menu-item key="5">
          <a-icon type="profile" />
          <span class="nav-text">Audit Log</span>
        </a-menu-item>
        <a-menu-item key="6">
          <a-icon type="flag" />
          <span class="nav-text">Flagged Queue</span>
        </a-menu-item>
        <a-menu-item key="7">
          <a-icon type="user" />
          <span class="nav-text">Device Detective</span>
        </a-menu-item>
        <a-menu-item key="8">
          <a-icon type="setting" />
          <span class="nav-text">Settings</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout style="background: #EBEBEB;">
      <a-layout-header :style="{ background: '#fff', padding: 0 }">
        <div class="left">
          <a-button icon="copy"></a-button>
          <div class="topbar-breadcrumb">Home</div>
        </div>
        <div class="right">
          <a-input placeholder="Search...">
            <a-icon slot="prefix" type="search" />
          </a-input>
        </div>
        <div class="super-right">
          <div class="menu-item">
            <a-icon type="appstore" style="color: #C5CED2;"></a-icon>
          </div>
          <div class="menu-item">
            <a-icon type="question-circle" style="color: #C5CED2;" />
          </div>
        </div>
      </a-layout-header>
      <a-layout-content :style="{ margin: '24px 16px 0' }">
        <slot />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
export default {
  data() {
    return {
      selectedKeys: ["tsos"],
    };
  },

  methods: {
    handleMenuItemClicked(e) {
      if (e.key == 'lightspeed-lockdown') {
        this.$router.push('/lightspeed/lockdown');
        return false;
      }
      if (e.key == 'lightspeed-announcements') {
        this.$router.push('/lightspeed/announcements');
        return false;
      }
      if (e.key == 'lightspeed-incidents') {
        this.$router.push('/lightspeed/incidents');
        return false;
      }
      if (e.key == 'lightspeed-guidance') {
        this.$router.push('/lightspeed/guidance');
        return false;
      }
    }
  }
};
</script>

<style lang="scss">
#lightspeed-layout {
  .ant-layout-sider {
    background: #3C444C;
  }

  .lightspeed-sidebar-menu {
    height: 100%;
    background: #3C444C;
    margin-top: 15px;

    .ant-menu-item-selected {
      background: transparent;
      font-weight: 400;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .ant-menu-sub {
      box-shadow: none;
      background: #343C43 !important;
    }

    .teamsos-menu {
      padding-bottom: 13px;
      margin-bottom: 13px;
      // border-bottom: 2px solid #373F46;

      .ant-menu-item {
        // padding-top: 0;
        // padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        height: 45px;
        line-height: 45px;
      }

      .tsos-menu-inner {
        // padding-top: 4px;
        // padding-bottom: 4px;
        border-left: 1px solid #40474E;
        padding-left: 20px;
      }
    }
  }

  .ant-layout-sider-trigger {
    background: #4C535C;
  }

  .ant-layout-header {
    // border-bottom: 2px solid #F3F3F3;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
    height: 50px;
    line-height: 50px;
    padding-left: 12px !important;

    display: flex;
    align-items: center;

    .left {
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding-top: 7px !important;
      padding-bottom: 7px !important;
    }

    .right {
      flex-shrink: 1;
      padding-top: 7px !important;
      padding-bottom: 7px !important;
      padding-right: 12px !important
    }

    .super-right {
      flex-shrink: 1;
      display: flex;
      height: 100%;

      .menu-item {
        width: 45px;
        border-left: 1px solid #F2F2F2;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .topbar-breadcrumb {
      padding-left: 15px;
    }
  }

  .lightspeed-logo {
    background: #00B0DB;
    // border-bottom: 2px solid #B0B3B6;
    height: 50px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }
}

#lightspeed-layout {
  background: #f4f6fa;
  height: 100%;

  .form-inner {
    margin-top: 20px;
  }

  .form-last {
    margin-bottom: 10px;
  }

  .ant-btn-primary {
    background: #3398c6 !important;
    border-radius: 5px;

    &:disabled {
      opacity: 0.7;
    }
  }

  .card {
    // border: 1px solid #ebedef;
    border-radius: 2px;
    overflow: hidden;

    box-shadow: 0 0 7px rgb(0 0 0 / 0.04);

    .nav {
      background: #2d353b;
      display: flex;

      .nav-item {
        color: #fff;
        padding: 15px;
        cursor: pointer;

        &.active {
          font-weight: 500;
        }
      }
    }

    .header {
      background: #fff;
      padding: 16px 20px;
      display: flex;
      justify-items: center;
      align-items: center;
      border-bottom: 2px solid #F9F9F9;

      h3 {
        // color: #fff;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .search {
      text-align: right;
      display: block;
    }

    .body {
      background: #fff;
      padding: 20px;

      &.bb {
        border-bottom: 2px solid #F9F9F9;
      }
    }
  }

  // .ant-layout-header {
  //   height: 50px;

  //   .logo {
  //     float: left;
  //     width: 180px;
  //     height: 50px;
  //     display: flex;
  //     align-items: center;

  //     img {
  //       height: 17px;
  //     }
  //   }

  //   .ant-menu {
  //     line-height: 50px !important;

  //     .ant-menu-item {
  //       color: #fff;

  //       &:hover,
  //       &.ant-menu-item-selected {
  //         background: #fff !important;
  //         color: #2d353b;
  //       }
  //     }
  //   }
  // }

  // .ant-layout-header {
  //   padding: 0 20px;
  // }

  .main-nav {
    z-index: 90;
  }

  .second-nav {
    background: #fff;
    // border-bottom: 1px solid #e3ebf6;
    display: flex;

    box-shadow: 0 3px 10px #888888;
    z-index: 50;

    .search {
      flex-shrink: 1;
      padding: 35px 0;
      background: #3497c6;
      width: 25px;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }

    .icon-wrapper {
      flex-grow: 1;
      display: flex;
      padding-left: 15px;
      padding-top: 15px;
      padding-bottom: 15px;

      .icon {
        &.guidance {
          margin-left: 15px;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding-right: 15px;

        &:hover {
          cursor: pointer;

          .icon-inner {
            background: #eee;
          }
        }

        font-size: 12px;

        .icon-inner {
          border: 1px #2d353b solid;
          height: 40px;
          width: 40px;
          display: flex;
          align-content: center;
          justify-content: center;
          border-radius: 9px;
          align-items: center;
          justify-items: center;
          margin-bottom: 5px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>